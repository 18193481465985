import { useEffect, useState } from "react";
import { useGetFarmsByCreator } from "../../components/CustomHooks/useGetFarmsByCreator";
import LoadingDiv from "../../components/LoadingDiv";
import { useStateContext } from "../../contexts/ContextProvider";
import { currentUsername, currentWebsiteURL } from "../../data/config";
import { resizerPrefix, resizerSuffix } from "../../data/constants";
import { ClaimInfo, TokenCard } from "../../data/css/WalletStyles";

import { SmallButton } from "../../Styles";
import { buildIpfsUrl } from "../../data/functions/global_functions";
import { useGetV2FarmsByCreator } from "../../components/CustomHooks/useGetV2FarmsByCreator";

export const showManageFarmsSection = (currentToolsSection) => {
  const {
    enterModalDisplay,
    setEnterModalDisplay,
    enterModalText,
    setEnterModalText,
  } = useStateContext();

  const [farmsByCreator, getFarmsByCreator, creatorFarmsAreLoading] =
    useGetFarmsByCreator(currentUsername);
  const [v2FarmsByCreator, getV2FarmsByCreator, v2CreatorFarmsAreLoading] = 
    useGetV2FarmsByCreator(currentUsername);
  const [showFarms, setShowFarms] = useState(false);

  useEffect(() => {
    if (currentToolsSection == "Manage Farms") {
      getFarmsByCreator(currentUsername, setShowFarms);
      getV2FarmsByCreator(currentUsername);
    }
  }, [currentToolsSection]);

  return (
    <span className={currentToolsSection != "Manage Farms" && "hidden"}>
      {(!creatorFarmsAreLoading && !v2CreatorFarmsAreLoading) && (
        <span>
          {farmsByCreator?.length > 0
            && farmsByCreator.map((item, index) => (
                <span key={index}>
                  <TokenCard>
                    <a href={`${currentWebsiteURL}/farm/${item.farmname}`}>
                      <img
                        src={buildIpfsUrl(item.logo, 200)}
                        onError={(e) =>
                          (e.target.onerror = null)(
                            (e.target.src =
                              "https://logos.waxdao.io/default-logo.png")
                          )
                        }
                      />
                    </a>
                    <ClaimInfo>
                      {item.farmname}
                        <a href={`${currentWebsiteURL}/edit-farm/${item.farmname}`}>
                      <SmallButton>Edit Farm</SmallButton>
                      </a>
                    </ClaimInfo>
                  </TokenCard>
                </span>
              ))}

{v2FarmsByCreator?.length > 0
            && v2FarmsByCreator.map((item, index) => (
                <span key={index}>
                  <TokenCard>
                    <a href={`${currentWebsiteURL}/v2/farm/${item.farmname}`}>
                      <img
                        src={buildIpfsUrl(item.profile?.avatar, 200)}
                        onError={(e) =>
                          (e.target.onerror = null)(
                            (e.target.src =
                              "https://logos.waxdao.io/default-logo.png")
                          )
                        }
                      />
                    </a>
                    <ClaimInfo>
                      {item.farmname}
                        <a href={`${currentWebsiteURL}/v2/edit-farm/${item.farmname}`}>
                      <SmallButton>Edit Farm</SmallButton>
                      </a>
                    </ClaimInfo>
                  </TokenCard>
                </span>
              ))}
            


            
            {(farmsByCreator?.length == 0 && v2FarmsByCreator?.length == 0) && (
              <div className="w-100 text-center pt-3">No farms to show</div>
            ) }
          
          
        </span>
      )}
      {(creatorFarmsAreLoading || v2CreatorFarmsAreLoading) && <LoadingDiv />}
    </span>
  );
};
