import { useState } from "react";
import { currentApiList } from "../../data/config";
import axios from 'axios';

export const useGetV2FarmsByCreator = () => {
    
    const [farmsByCreator, setFarmsByCreator] = useState([]);
    const [creatorFarmsAreLoading, setCreatorFarmsAreLoading] = useState(true);

    const getFarmsByCreator = async (UserName) => {
        setCreatorFarmsAreLoading(true);
        
        for (const api of currentApiList) {
            try {
                const res = await axios.post(`${api}/v1/chain/get_table_rows`,{
                    table: "farms",
                    scope: "farms.waxdao",
                    code: "farms.waxdao",
                    key_type: "name",
                    index_position: 2,
                    limit: 100,
                    lower_bound: UserName,
                    upper_bound: UserName,
                    json: true,
                });

                if (res.data.rows) {
                    setFarmsByCreator(res.data.rows);
                    console.log(res.data.rows);
                    break;
                }
            } catch (error) {
                console.log(`Failed on API endpoint ${api}. Error: ${error}`);
            }
        }

        setCreatorFarmsAreLoading(false);
    }

    return [farmsByCreator, getFarmsByCreator, creatorFarmsAreLoading]
}
