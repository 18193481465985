import { useState } from "react";
import { currentApiList, currentUsername, getCurrentApiList, getCurrentAtomicApiList } from "../../data/config";
import axios from 'axios';

export const useGetStakedOnlyPools = () => {
    
    const [myPools, setMyPools] = useState([]);
    const [poolsAreLoading, setPoolsAreLoading] = useState(true);
    const [poolsWereLoaded, setPoolsWereLoaded] = useState(false);


    const getStakedOnlyPools = () => {
        
        axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
            table:"tokens",
            scope:"waxdaofarmer",
            code:"waxdaofarmer",
            key_type: 'name',
            index_position: '2',
            limit:1000,
            lower_bound:currentUsername,
            upper_bound:currentUsername,
            json:true
            }).then((res) => {
                setMyPools(res.data.rows);
                setPoolsWereLoaded(true);
                console.log("staked pools:")
                console.log(res.data.rows)
            })
            
            .catch((error) => console.log(error))
            .finally(() => setPoolsAreLoading(false))

    }

    return [myPools, getStakedOnlyPools, poolsAreLoading, poolsWereLoaded]
}